<template>
  <div>
    <tabs-for-date 
      :dates='sessionDates'
      :selected-index='dateIndex'
      @select-date-index='updateSessionVotingsByDateIndex'
      class='mt-8 mb-12 px-2 lg:px-0' />
    <div class='px-2 mb-8 flex flex-row justify-start gap-x-1 items-stretch'>
      <select class='rounded-md border py-2 px-4 w-full lg:w-1/2 lg:max-w-2xl flex-grow'
        v-model='selectedSessionId'
        @change='updateSelectedSessionIdQueryParam'>
        <option v-for='session in selectedDateSessionVotings'
          :key='`session-${session.id}`'
          :value='session.id'>
          {{ session.title }}
        </option>
      </select>
      <button
        @click='getSessionVotingQuestions'
        class='block border rounded-md p-2 flex-shrink-0 hover:shadow-md opacity-90 hover:opacity-100'>
        <refresh-icon class='h-4'/>
      </button>
    </div>
    <div v-if='filteredVotingSessions.length'
      class='relative pt-8 pb-16 px-2 lg:px-0' 
      style='min-height:calc(100vh - 28rem);'>
      <content-background-cover
        background-color='#FAFAFA'
        class='z-0 border-t' />
      <div v-for='session in filteredVotingSessions'
        :key='`session-voting-${session.id}`'
        class='mb-8'>
        <div class='flex flex-row justify-between items-center py-2'>
          <h1 class='text-lg font-medium text-gray-900'>
            {{session.title}}
          </h1>
          <div class='text-gray-500'>
              {{formattedTimeString(session.scheduledStartTime)}} - {{formattedTimeString(session.scheduledEndTime)}}
          </div>
        </div>
        <div v-for='(voting, index) in sortQuestions(session.listOfSessionVotings)'
          :key='`voting-${voting.id}`'
          class='bg-white mt-2 mb-6 p-4 rounded hover:shadow-md cursor-pointer standard-transition'
          @click='goToQuestion(voting)'>
          <div class='py-0.5 flex flex-row justify-start items-start gap-x-4'>
            <label class='block flex-shrink-0 flex-grow-0 text-center font-medium text-gray-800 w-10 h-10 py-2 rounded-md bg-gray-100 uppercase'>q{{index + 1}}</label>
            <p class='text-sm lg:text-normal uppercase text-gray-600  whitespace-pre-line pt-2.5'>{{ voting.question }}</p>
          </div>
          <div
            class='ml-12 text-blue-800 text-xs lg:text-sm pl-2 pb-2 uppercase font-medium tracking-wide flex flex-row justify-start items-center gap-x-0.5'>
            <span>{{actionText(voting.status, voting.me.sessionVotingResult.choiceText)}}</span>
            <arrow-narrow-right-icon class='inline-block h-4' />
          </div>
        </div>
      </div>
    
    </div>
    <div v-else
      class='relative'
      style='min-height:calc(100vh - 28rem);'>
      <content-background-cover
        background-color='#FAFAFA'
        class='z-0 border-t' />
      <div class='max-w-5xl w-full p-8 py-16 lg:p-16 rounded-md mx-auto text-center'>No questions for this combination of date, session, and status are available.<br>Please select a different date, session, or answer status.</div>
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import TabsForDate            from '@/components/TabsForDate.vue'

import cloneDeep from 'lodash/cloneDeep'
import { ArrowNarrowRightIcon, RefreshIcon } from 'vue-tabler-icons'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'SessionVotingResults',
  components: {
    ContentBackgroundCover,
    TabsForDate,
    ArrowNarrowRightIcon,
    RefreshIcon,
  },
  data () {
    return {
      selectedTab: 'all',
      selectedSessionId : 0,
      dateIndex: 0,
      updateVotingQuestionsTimer: null
    }
  },
  watch: {
    currentQueryParamDayIndex: {
      handler: function (newVal) {
        if (newVal) { 
          this.dateIndex = newVal - 1
        }
      }, 
      immediate: true
    },
    sessionsDateCount: {
      handler: function (newVal) { 
        if (newVal > 1 && !this.currentQueryParamDayIndex) {
          this.updateSessionVotingsByDateIndex(this.defaultDateIndex)
        }
      },
      immediate: true
    },
    selectedDateSessionVotings: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal.length > 0) {
          if (this.currentQueryParamSessionId &&
              newVal.map(session => session.id).includes(this.currentQueryParamSessionId)) {
            this.selectedSessionId = this.currentQueryParamSessionId
          } else {
            this.selectedSessionId = newVal[0].id
            this.updateSelectedSessionIdQueryParam()
          }
        }
      }, 
      immediate: true,
      deep:true,
    },
  },
  computed: {
    ...mapState('sessionVoting', [
      'sessionVotingQuestions',
    ]),
    ...mapGetters('sessionVoting', [
      'hasVotingQuestions',
    ]),
    filteredVotingSessions () {
      return this.selectedDateSessionVotings.filter(session => {
        return session.id === this.selectedSessionId
      })
    },
    sessionDates () {
      return this.sessionVotingQuestions.map(sessionVotings => {
        return dayjs(sessionVotings.date).format('YYYY-MM-DD')
      })
    },
    selectedDateSessionVotings () {
      return (this.hasVotingQuestions) ? this.sessionVotingQuestions[this.dateIndex].listOfSessions : []
    },
    defaultDateIndex () {
      let todaysDate = dayjs(new Date()).tz("Asia/Seoul").format('YYYY-MM-DD')
      let index = this.sessionDates.findIndex(date => date === todaysDate)
      if (index > 0) {
        return index
      } else {
        return 0
      } 
    },
    sessionsDateCount () {
      return this.sessionDates.length
    },
    hasSessionVotingsDayIndexQuery () {
      return this.$route.query.session_votings_day_index // this should use the human readable version
    },
    hasSelectedSessionIdQuery () {
      return this.$route.query.selected_session_id
    },
    currentQueryParamDayIndex () {
      return (this.hasSessionVotingsDayIndexQuery) ? parseInt(this.$route.query.session_votings_day_index) : 0
    },
    currentQueryParamSessionId () {
      return (this.hasSelectedSessionIdQuery) ? parseInt(this.$route.query.selected_session_id) : 0
    },
  },
  methods: {
    ...mapActions('sessionVoting', [
      'getSessionVotingQuestions',
    ]),
    goToQuestion (votingQuestion) {
      if (['finished', 'open'].includes(votingQuestion.status)) {
        this.$router.push({ name: 'SessionVotingResult', query: {...this.$route.query, session_voting_id: votingQuestion.id } })
      }
    },
    formattedTimeString (date) {
      return dayjs(date).tz('Asia/Seoul').format('HH:mm')
    },
    actionText (status, text) {
      switch (status) {
        case 'open':
          return (text) ? 'Update Answer' : 'Answer Question'
        case 'finished':
          return 'See Results'
        default:
          return 'View'
      }
    },
    sortQuestions(listOfSessionVotings) {
      console.log('list of session votings', listOfSessionVotings)
      let questions = cloneDeep(listOfSessionVotings)
      questions = questions.sort((a, b) => {
        return a.id - b.id
      })
      return questions
    },
    updateSessionVotingsByDateIndex (index) {
      if (this.dateIndex !== index) {
        this.updateSessionVotingsDayIndexQueryParam(index + 1)
      }
    },
    updateSelectedSessionIdQueryParam () {
      this.$router.push({ query: {...this.$route.query, selected_session_id: this.selectedSessionId} })
    },
    updateSessionVotingsDayIndexQueryParam (newIndex) {
      if (this.dateIndex !== (newIndex - 1)) {
        this.$router.push({ query: {...this.$route.query, session_votings_day_index: newIndex} })
      }
    },
  },
  mounted () {
    this.getSessionVotingQuestions()
    this.updateVotingQuestionsTimer = setInterval(() => {
      this.getSessionVotingQuestions()
    }, 180000) // per 30 seconds
  },
  beforeDestroy () {
    clearInterval(this.updateVotingQuestionsTimer)
  },
}
</script>
